import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overall-benefits',
  templateUrl: './overall-benefits.component.html',
  styleUrls: ['./overall-benefits.component.scss']
})
export class OverallBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
