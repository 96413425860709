import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaboration-agent',
  templateUrl: './collaboration-agent.component.html',
  styleUrls: ['./collaboration-agent.component.scss']
})
export class CollaborationAgentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
