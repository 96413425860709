import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruitment-benefits',
  templateUrl: './recruitment-benefits.component.html',
  styleUrls: ['./recruitment-benefits.component.scss']
})
export class RecruitmentBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
