import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soft-skills-benefits',
  templateUrl: './soft-skills-benefits.component.html',
  styleUrls: ['./soft-skills-benefits.component.scss']
})
export class SoftSkillsBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
