import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
  
})

export class MainBannerComponent implements OnInit {

constructor() { 
}

ngOnInit() {
  
}

@ViewChild('carousel') carousel: any;
  slides: Array<Object> = [
    {'src': './assets/images/banner1.jpg'},
    {'src': './assets/images/banner2.jpg'},
    {'src': './assets/images/banner1.jpg'},
    {'src': './assets/images/banner2.jpg'},
    {'src': './assets/images/banner1.jpg'},
    {'src': './assets/images/banner2.jpg'},
    {'src': './assets/images/banner1.jpg'},
    {'src': './assets/images/banner2.jpg'},
  ];
  options: Object = {
    clicking: true,
    sourceProp: 'src',
    visible: 5,
    perspective: 20,
    startSlide: 0,
    border: 0,
    dir: 'ltr',
    width: 650,
    height: 358,
    space: 294,
    autoRotationSpeed: 5000,
    loop: true
  };
  slideClicked (index) {
  this.carousel.slideClicked(index);
  }

}
