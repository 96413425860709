import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

  courseslide = [
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
    {
      img: "assets/images/course.jpg",
    },
  ];

  coursecategorycarousel = {
    "slidesToShow": 4, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":false,
    "infinite": false,
    "arrows": true,
    "autoplay": true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: "false"
        }
      }
    ]
  };

  testimonial = [
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
    {
      img: "assets/images/client1.jpg",
      content: " “Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam” "
    },
  ];

  testimonialcarousel = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":false,
    "infinite": false,
    "arrows": true,
    "autoplay": true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: "false"
        }
      }
    ]
  };

  certificate = [
    {
      img: "assets/images/certificate1.jpg",
    },
    {
      img: "assets/images/certificate2.jpg",
    },
    {
      img: "assets/images/certificate1.jpg",
    },
    {
      img: "assets/images/certificate2.jpg",
    }
  ]

  certificatecarousel = {
    "slidesToShow": 2, 
    "slidesToScroll": 1,
    "dots":true,
    "infinite": false,
    "arrows": false,
    "autoplay": true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: "false"
        }
      }
    ]
  };



}
