import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-full',
  templateUrl: './review-full.component.html',
  styleUrls: ['./review-full.component.scss']
})
export class ReviewFullComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
