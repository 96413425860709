import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-reviews',
  templateUrl: './course-reviews.component.html',
  styleUrls: ['./course-reviews.component.scss'],
})
export class CourseReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
