import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaboration-customers',
  templateUrl: './collaboration-customers.component.html',
  styleUrls: ['./collaboration-customers.component.scss']
})
export class CollaborationCustomersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
