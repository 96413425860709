import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-highlights',
  templateUrl: './course-highlights.component.html',
  styleUrls: ['./course-highlights.component.scss']
})
export class CourseHighlightsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
