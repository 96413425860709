import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'; 
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(document).ready(function() {
      // var windowWidth = $(window).width();
      // if (windowWidth > 1024) $("#carousel").waterwheelCarousel({
      //     horizon: 10,
      //     horizonOffset: -54,
      //     horizonOffsetMultiplier: .1,
      //     separation: 280,
      //     speed: 600,
      //     opacityMultiplier: 1,
      //     activeClassName: "current_gallery"
      // })
  });

  }

}
