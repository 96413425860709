import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardleftmenu-classroom',
  templateUrl: './dashboardleftmenu-classroom.component.html',
  styleUrls: ['./dashboardleftmenu-classroom.component.scss']
})
export class DashboardleftmenuClassroomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
