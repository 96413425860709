import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruitment-academy',
  templateUrl: './recruitment-academy.component.html',
  styleUrls: ['./recruitment-academy.component.scss']
})
export class RecruitmentAcademyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var acc = document.getElementsByClassName("categoryaccordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }

}
