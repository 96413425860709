import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-notes',
  templateUrl: './course-notes.component.html',
  styleUrls: ['./course-notes.component.scss']
})
export class CourseNotesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
