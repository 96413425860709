import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-benefits',
  templateUrl: './course-benefits.component.html',
  styleUrls: ['./course-benefits.component.scss']
})
export class CourseBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
