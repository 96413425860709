import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ViewChild } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2Carousel3dModule }  from 'ng2-carousel-3d';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardleftmenuComponent } from './dashboardleftmenu/dashboardleftmenu.component';
import { HomeComponent } from './home/home.component';
import { CourseListComponent } from './course-list/course-list.component';
import { MainBannerComponent } from './main-banner/main-banner.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { AsideComponent } from './aside/aside.component';
import { EligibilityComponent } from './eligibility/eligibility.component';
import { SyllabusComponent } from './syllabus/syllabus.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { CertificationComponent } from './certification/certification.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { CareerPossibilitiesComponent } from './career-possibilities/career-possibilities.component';
import { CourseReviewsComponent } from './course-reviews/course-reviews.component';
import { CourseModeratorComponent } from './course-moderator/course-moderator.component';
import { ContactComponent } from './contact/contact.component';
import { CareerWithUsComponent } from './career-with-us/career-with-us.component';
import { CartComponent } from './cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { ReviewFullComponent } from './review-full/review-full.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { CollaborationComponent } from './collaboration/collaboration.component';
import { CollaborationBlogComponent } from './collaboration-blog/collaboration-blog.component';
import { CollaborationFreelanceComponent } from './collaboration-freelance/collaboration-freelance.component';
import { CollaborationTimeComponent } from './collaboration-time/collaboration-time.component';
import { CollaborationAgentComponent } from './collaboration-agent/collaboration-agent.component';
import { CollaborationCustomersComponent } from './collaboration-customers/collaboration-customers.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GdprExplainedComponent } from './gdpr-explained/gdpr-explained.component';
import { AccessYourDataComponent } from './access-your-data/access-your-data.component';
import { PrivacyContactComponent } from './privacy-contact/privacy-contact.component';
// import { MainCoursesComponent } from './main-courses/main-courses.component';
import { SpecialCoursesComponent } from './special-courses/special-courses.component';
import { CourseBenefitsHomeComponent } from './course-benefits-home/course-benefits-home.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { CareerComponent } from './career/career.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MyProfileComponent } from './dashboard/my-profile/my-profile.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { MyPointsComponent } from './dashboard/my-points/my-points.component';
import { CourseWishlistComponent } from './dashboard/course-wishlist/course-wishlist.component';
import { MyCoursesComponent } from './dashboard/my-courses/my-courses.component';
import { MailboxComponent } from './dashboard/mailbox/mailbox.component';
import { AllCoursesComponent } from './dashboard/all-courses/all-courses.component';
import { DashboardleftmenuClassroomComponent } from './dashboardleftmenu-classroom/dashboardleftmenu-classroom.component';
import { OngoingCoursesComponent } from './dashboard/ongoing-courses/ongoing-courses.component';
import { CompleteCoursesComponent } from './dashboard/complete-courses/complete-courses.component';
import { CourseInstructionsComponent } from './dashboard/course-instructions/course-instructions.component';
import { DashboardleftmenuCourseComponent } from './dashboardleftmenu-course/dashboardleftmenu-course.component';
import { CourseHighlightsComponent } from './dashboard/course-highlights/course-highlights.component';
import { CourseBenefitsComponent } from './dashboard/course-benefits/course-benefits.component';
import { CourseChaptersComponent } from './dashboard/course-chapters/course-chapters.component';
import { CourseAssessmentsComponent } from './dashboard/course-assessments/course-assessments.component';
import { CourseNotesComponent } from './dashboard/course-notes/course-notes.component';
import { CourseLibraryComponent } from './dashboard/course-library/course-library.component';
import { CourseDetailReviewsComponent } from './dashboard/course-detail-reviews/course-detail-reviews.component';
import { ChapterModalComponent } from './dashboard/chapter-modal/chapter-modal.component';
import { CourseAssessmentsChapterComponent } from './dashboard/course-assessments-chapter/course-assessments-chapter.component';
import { CourseTestResultsComponent } from './dashboard/course-test-results/course-test-results.component';
import { BlogComponent } from './blog/blog.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AllAcademyCourseComponent } from './courses/all-academy-course/all-academy-course.component';
import { RecruitmentAcademyComponent } from './courses/recruitment-academy/recruitment-academy.component';
import { EmploymentAcademyComponent } from './courses/employment-academy/employment-academy.component';
import { EntrepreneursAcademyComponent } from './courses/entrepreneurs-academy/entrepreneurs-academy.component';
import { SoftSkillsAcademyComponent } from './courses/soft-skills-academy/soft-skills-academy.component';
import { RecruitmentBenefitsComponent } from './benefits/recruitment-benefits/recruitment-benefits.component';
import { EntrepreneurBenefitsComponent } from './benefits/entrepreneur-benefits/entrepreneur-benefits.component';
import { OverallBenefitsComponent } from './benefits/overall-benefits/overall-benefits.component';
import { EmploymentBenefitsComponent } from './benefits/employment-benefits/employment-benefits.component';
import { SoftSkillsBenefitsComponent } from './benefits/soft-skills-benefits/soft-skills-benefits.component';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CourseListComponent,
    MainBannerComponent,
    CourseDetailsComponent,
    AsideComponent,
    EligibilityComponent,
    SyllabusComponent,
    AssessmentComponent,
    CertificationComponent,
    BenefitsComponent,
    CareerPossibilitiesComponent,
    CourseReviewsComponent,
    CourseModeratorComponent,
    ContactComponent,
    CareerWithUsComponent,
    CartComponent,
    PaymentComponent,
    ReviewFullComponent,
    LoginComponent,
    RegisterComponent,
    TermsOfUseComponent,
    CollaborationComponent,   
    CookiesPolicyComponent, 
    PrivacyPolicyComponent,
    GdprExplainedComponent,
    AccessYourDataComponent,
    PrivacyContactComponent,
    FaqComponent,
    ContactUsComponent,
    CollaborationBlogComponent,
    CollaborationFreelanceComponent,
    CollaborationTimeComponent,
    CollaborationAgentComponent,
    CollaborationCustomersComponent,
    MyProfileComponent,
    SettingsComponent,
    DashboardleftmenuComponent,
    PaymentsComponent,
    MyPointsComponent,
    CourseWishlistComponent,
    MyCoursesComponent,
    MailboxComponent,
    AllCoursesComponent,
    DashboardleftmenuClassroomComponent,
    OngoingCoursesComponent,
    CompleteCoursesComponent,
    CourseInstructionsComponent,
    DashboardleftmenuCourseComponent,
    CourseHighlightsComponent,
    CourseBenefitsComponent,
    CourseChaptersComponent,
    CourseAssessmentsComponent,
    CourseNotesComponent,
    CourseLibraryComponent,
    CourseDetailReviewsComponent,
    ChapterModalComponent,
    CourseAssessmentsChapterComponent,
    CourseTestResultsComponent,
    // MainCoursesComponent,
    EnrollmentComponent,
    CareerComponent,
    WhyUsComponent,
    TestimonialsComponent,
    SpecialCoursesComponent,
    CourseBenefitsHomeComponent,
    BlogComponent,
    AboutUsComponent,
    RecruitmentAcademyComponent,
    EmploymentAcademyComponent,
    EntrepreneursAcademyComponent,
    SoftSkillsAcademyComponent,
    AllAcademyCourseComponent,
    RecruitmentBenefitsComponent,
    EntrepreneurBenefitsComponent,
    OverallBenefitsComponent,
    EmploymentBenefitsComponent,
    SoftSkillsBenefitsComponent,

  ],


  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    Ng2Carousel3dModule,
  ],

  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { 
}
