import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-contact',
  templateUrl: './privacy-contact.component.html',
  styleUrls: ['./privacy-contact.component.scss']
})
export class PrivacyContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
