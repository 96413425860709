import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardleftmenu',
  templateUrl: './dashboardleftmenu.component.html',
  styleUrls: ['./dashboardleftmenu.component.scss']
})
export class DashboardleftmenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
