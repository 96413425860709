import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-wishlist',
  templateUrl: './course-wishlist.component.html',
  styleUrls: ['./course-wishlist.component.scss']
})
export class CourseWishlistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
