import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { AllAcademyCourseComponent } from './courses/all-academy-course/all-academy-course.component';
import { RecruitmentAcademyComponent } from './courses/recruitment-academy/recruitment-academy.component';
import { EmploymentAcademyComponent } from './courses/employment-academy/employment-academy.component';
import { EntrepreneursAcademyComponent } from './courses/entrepreneurs-academy/entrepreneurs-academy.component';
import { SoftSkillsAcademyComponent } from './courses/soft-skills-academy/soft-skills-academy.component';
import { EligibilityComponent } from './eligibility/eligibility.component';
import { SyllabusComponent } from './syllabus/syllabus.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { CertificationComponent } from './certification/certification.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { CareerPossibilitiesComponent } from './career-possibilities/career-possibilities.component';
import { CourseReviewsComponent } from './course-reviews/course-reviews.component';
import { ReviewFullComponent } from './review-full/review-full.component';
import { CourseModeratorComponent } from './course-moderator/course-moderator.component';
import { ContactComponent } from './contact/contact.component';
import { CareerWithUsComponent } from './career-with-us/career-with-us.component';
import { CartComponent } from './cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { BlogComponent } from './blog/blog.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { CollaborationComponent } from './collaboration/collaboration.component';
import { CollaborationBlogComponent } from './collaboration-blog/collaboration-blog.component';
import { CollaborationFreelanceComponent } from './collaboration-freelance/collaboration-freelance.component';
import { CollaborationTimeComponent } from './collaboration-time/collaboration-time.component';
import { CollaborationAgentComponent } from './collaboration-agent/collaboration-agent.component';
import { CollaborationCustomersComponent } from './collaboration-customers/collaboration-customers.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GdprExplainedComponent } from './gdpr-explained/gdpr-explained.component';
import { AccessYourDataComponent } from './access-your-data/access-your-data.component';
import { PrivacyContactComponent } from './privacy-contact/privacy-contact.component';
// import { MainCoursesComponent } from './main-courses/main-courses.component';
import { SpecialCoursesComponent } from './special-courses/special-courses.component';
import { CourseBenefitsHomeComponent } from './course-benefits-home/course-benefits-home.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { CareerComponent } from './career/career.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MyProfileComponent } from './dashboard/my-profile/my-profile.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { MyPointsComponent } from './dashboard/my-points/my-points.component';
import { CourseWishlistComponent } from './dashboard/course-wishlist/course-wishlist.component';
import { MyCoursesComponent } from './dashboard/my-courses/my-courses.component';
import { MailboxComponent } from './dashboard/mailbox/mailbox.component';
import { AllCoursesComponent } from './dashboard/all-courses/all-courses.component';
import { OngoingCoursesComponent } from './dashboard/ongoing-courses/ongoing-courses.component';
import { CompleteCoursesComponent } from './dashboard/complete-courses/complete-courses.component';
import { CourseInstructionsComponent } from './dashboard/course-instructions/course-instructions.component';
import { CourseHighlightsComponent } from './dashboard/course-highlights/course-highlights.component';
import { CourseBenefitsComponent } from './dashboard/course-benefits/course-benefits.component';
import { CourseChaptersComponent } from './dashboard/course-chapters/course-chapters.component';
import { CourseAssessmentsComponent } from './dashboard/course-assessments/course-assessments.component';
import { CourseNotesComponent } from './dashboard/course-notes/course-notes.component';
import { CourseLibraryComponent } from './dashboard/course-library/course-library.component';
import { CourseDetailReviewsComponent } from './dashboard/course-detail-reviews/course-detail-reviews.component';
import { CourseAssessmentsChapterComponent } from './dashboard/course-assessments-chapter/course-assessments-chapter.component';
import { CourseTestResultsComponent } from './dashboard/course-test-results/course-test-results.component';

import { OverallBenefitsComponent } from './benefits/overall-benefits/overall-benefits.component';
import { RecruitmentBenefitsComponent } from './benefits/recruitment-benefits/recruitment-benefits.component';
import { EntrepreneurBenefitsComponent } from './benefits/entrepreneur-benefits/entrepreneur-benefits.component';
import { EmploymentBenefitsComponent } from './benefits/employment-benefits/employment-benefits.component';
import { SoftSkillsBenefitsComponent } from './benefits/soft-skills-benefits/soft-skills-benefits.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'course-list', component: CourseListComponent },
  { path: 'course-details', component: CourseDetailsComponent }, 
  { path: 'eligibility', component: EligibilityComponent },
  { path: 'syllabus', component: SyllabusComponent },
  { path: 'assessment', component: AssessmentComponent },
  { path: 'certification', component: CertificationComponent },
  { path: 'benefits', component: BenefitsComponent },
  { path: 'career-possibilities', component: CareerPossibilitiesComponent },
  { path: 'course-reviews', component: CourseReviewsComponent },
  { path: 'review-full', component: ReviewFullComponent },
  { path: 'course-moderator', component: CourseModeratorComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'career-with-us', component: CareerWithUsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'collaboration', component: CollaborationComponent },
  { path: 'collaboration-blog', component: CollaborationBlogComponent },
  { path: 'collaboration-freelance', component: CollaborationFreelanceComponent },
  { path: 'collaboration-time', component: CollaborationTimeComponent },
  { path: 'collaboration-agent', component: CollaborationAgentComponent },
  { path: 'collaboration-customers', component: CollaborationCustomersComponent },
  { path: 'cookies-policy', component: CookiesPolicyComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'gdpr-explained', component: GdprExplainedComponent },
  { path: 'access-your-data', component: AccessYourDataComponent },
  { path: 'privacy-contact', component: PrivacyContactComponent },
  // { path: 'main-courses', component: MainCoursesComponent },
  { path: 'courses/all-courses', component: AllAcademyCourseComponent },
  { path: 'courses/recruitment-academy', component: RecruitmentAcademyComponent },
  { path: 'courses/employment-academy', component: EmploymentAcademyComponent },
  { path: 'courses/entrepreneurs-academy', component: EntrepreneursAcademyComponent },
  { path: 'courses/soft-skills-academy', component: SoftSkillsAcademyComponent },

  { path: 'benefits/overall-benefits', component: OverallBenefitsComponent },
  { path: 'benefits/recruitment-benefits', component: RecruitmentBenefitsComponent },
  { path: 'benefits/entrepreneur-benefits', component: EntrepreneurBenefitsComponent },
  { path: 'benefits/employment-benefits', component: EmploymentBenefitsComponent },
  { path: 'benefits/soft-skills-benefits', component: SoftSkillsBenefitsComponent },
  
  
  { path: 'special-courses', component: SpecialCoursesComponent },
  { path: 'course-benefits-home', component: CourseBenefitsHomeComponent },
  { path: 'enrollment', component: EnrollmentComponent },
  { path: 'career', component: CareerComponent },
  { path: 'why-us', component: WhyUsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'dashboard/my-profile', component: MyProfileComponent },
  { path: 'dashboard/settings', component: SettingsComponent },
  { path: 'dashboard/payments', component: PaymentsComponent },
  { path: 'dashboard/my-points', component: MyPointsComponent },
  { path: 'dashboard/course-wishlist', component: CourseWishlistComponent },
  { path: 'dashboard/my-courses', component: MyCoursesComponent },
  { path: 'dashboard/mailbox', component: MailboxComponent },
  { path: 'dashboard/all-courses', component: AllCoursesComponent },
  { path: 'dashboard/ongoing-courses', component: OngoingCoursesComponent },
  { path: 'dashboard/complete-courses', component: CompleteCoursesComponent },
  { path: 'dashboard/course-instructions', component: CourseInstructionsComponent },
  { path: 'dashboard/course-highlights', component: CourseHighlightsComponent },
  { path: 'dashboard/course-benefits', component: CourseBenefitsComponent },
  { path: 'dashboard/course-chapters', component: CourseChaptersComponent },
  { path: 'dashboard/course-assessments', component: CourseAssessmentsComponent },
  { path: 'dashboard/course-notes', component: CourseNotesComponent },
  { path: 'dashboard/course-library', component: CourseLibraryComponent },
  { path: 'dashboard/course-detail-reviews', component: CourseDetailReviewsComponent },
  { path: 'dashboard/course-assessments/course-assessments-chapter', component: CourseAssessmentsChapterComponent },
  { path: 'dashboard/course-assessments/course-test-results', component: CourseTestResultsComponent },
  
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  // exports: [RouterModule]
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

